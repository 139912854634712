body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 1.5;
  color: #444;
  touch-action: manipulation;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.popup-overlay {
  overflow-y: scroll;
}

.clear {
  content: "";
  display: block;
  clear: both;
}

.full-width-images img {
  max-width: 100%;
  height: auto;
}

a {
  color: #02829D;
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  color: #003044;
}

table {
  border-collapse: collapse;
}

th, td {
  border: 1px solid #DDD;
  padding: 5px;
  font-size: 14px;
}

thead td {
  font-weight: bold;
  background: #F8F8F8;
}


.fb-button-wrapper {
  margin-top: 16px;
}

.fb-button-wrapper button {
  display: block;
  width: 100%;
  border-radius: 4px;
  text-transform: none;
}

.fb-button-wrapper .kep-login-facebook.medium {
  padding-top: 16px;
  padding-bottom: 16px;
}


